import { createRouter, createWebHistory } from 'vue-router';
import { HomePage } from './modules/HomePage'

const router = createRouter({
  history: createWebHistory(),
  // base: '/tcins/',
  routes: [
    ...HomePage
  ],
})

export default router