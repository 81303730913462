<template>
  <div id="app" style="-webkit-margin-collapse: separate">
    <router-view></router-view>
  </div>
</template>
<script setup></script>
<style scoped>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
</style>
