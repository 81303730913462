const Index = () => import('@/views/Index.vue')
const Home = () => import('@/views/page/Home.vue')
const About = () => import('@/views/page/About.vue')
const Claims = () => import('@/views/page/Claims.vue')
const Verification = () => import('@/views/page/Verification.vue')
const Publicity = () => import('@/views/page/Publicity.vue')
const Detail = () => import('@/views/page/Detail/Detail.vue')
const Notice = () => import('@/views/page/Detail/Notice.vue')
const Clause = () => import('@/views/page/Detail/Clause.vue')
const Inform = () => import('@/views/page/Detail/Inform.vue')
const HotelConsult = () => import('@/views/page/Outter/hotel/HotelConsult.vue')
const privacy = () => import('@/views/page/Outter/hotel/privacy.vue')
const Announcement = () => import('@/views/page/Announcement.vue')

const ROUTER_BASE = import.meta.env.VITE_ROUTER_BASE

export const HomePage = [
  {
    path: ROUTER_BASE,
    name: 'PCSite',
    redirect: ROUTER_BASE + 'home',
    component: Index,
    children: [
      {
        name: 'PCHome',
        path: ROUTER_BASE + 'home',
        component: Home
      },
      {
        name: 'PCAbout',
        path: ROUTER_BASE + 'about',
        component: About
      },
      {
        name: 'PCClaims',
        path: ROUTER_BASE + 'claims',
        component: Claims
      },
      {
        name: 'PCCheck',
        path: ROUTER_BASE + 'check',
        component: Verification
      },
      {
        name: 'Publicity',
        path: ROUTER_BASE + 'public',
        component: Publicity
      },
      {
        name: 'Announcement',
        path: ROUTER_BASE + 'announce',
        component: Announcement
      },
      {
        name: 'PCDetail',
        path: ROUTER_BASE + 'detail',
        component: Detail
      },
      {
        name: 'PCNotice',
        path: ROUTER_BASE + 'detail/notice',
        component: Notice
      },
      {
        name: 'PCClause',
        path: ROUTER_BASE + 'detail/clause',
        component: Clause
      },
      {
        name: 'PCInform',
        path: ROUTER_BASE + 'detail/inform',
        component: Inform
      },
      {
        name: 'PCHotelConsult',
        path: ROUTER_BASE + 'hotelConsult',
        component: HotelConsult
      },
      {
        name: 'PCPrivacy',
        path: ROUTER_BASE + '/privacy',
        component: privacy
      }
    ]
  }
]
